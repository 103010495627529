import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import loadable from './utils/loadable'
import 'animate.css'
import './style/base.scss'
import './style/App.scss'
// import 'react-notifications-component/dist/theme.css'
import 'react-notifications/lib/notifications.css'
// 公共模块
const DefaultLayout = loadable(() => import(/* webpackChunkName: 'default' */ './containers'))

// 基础页面
const View404 = loadable(() => import(/* webpackChunkName: '404' */ './views/Others/404'))
const View500 = loadable(() => import(/* webpackChunkName: '500' */ './views/Others/500'))
const Login = loadable(() => import(/* webpackChunkName: 'login' */ './views/Login'))
const GiftCardDetail = loadable(() => import(/* webpackChunkName: 'login' */ './views/GiftCardDetail'))

const Book = loadable(() => import(/* webpackChunkName: 'login' */ './views/Book'))
const CustomerLogin = loadable(() => import(/* webpackChunkName: 'login' */ './views/CustomerLogin'))
const CreateAccount = loadable(() => import(/* webpackChunkName: 'login' */ './views/CreateAccount'))
const ResetPassword = loadable(() => import(/* webpackChunkName: 'login' */ './views/ResetPassword'))
const VerifyEmail = loadable(() => import(/* webpackChunkName: 'login' */ './views/VerifyEmail'))
const CustomerAccount = loadable(() => import(/* webpackChunkName: 'login' */ './views/CustomerAccount'))
const CustomerAppointments = loadable(() => import(/* webpackChunkName: 'login' */ './views/CustomerAppointments'))
const BookStepOne = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookStepOne'))
const BookSelectStaff = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookSelectStaff'))
const BookSelectStaffOverview = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookSelectStaffOverview'))
const BookSelectService = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookSelectService'))
const BookSelectTime = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookSelectTime'))
const BookCart = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookCart'))
const BookCartCancel = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookCartCancel'))
const BookCartConfirm = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookCartConfirm'))
const BookSuccess = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookSuccess'))
const BookAddService = loadable(() => import(/* webpackChunkName: 'login' */ './views/BookAddService'))
const Location = loadable(() => import(/* webpackChunkName: 'login' */ './views/Location'))
const CancellationPolicy = loadable(() => import('./views/CancellationPolicy'))

const TapView = loadable(() => import(/* webpackChunkName: 'login' */ './views/TapView'))

const App = () => (
    <Router>
        <Switch>
            <Route path='/500' exact component={View500} />
            <Route path='/login' exact component={Login} />
            <Route path='/mobile/webview/:_id' exact component={TapView} />
            <Route path='/book' component={Book} />
            <Route path='/book/hr3' component={Book} />
            <Route path='/book/h3' component={Book} />
            <Route path='/book/hr4' component={Book} />
            <Route path='/book/h4' component={Book} />

            <Route path='/book-detail-summary' component={BookStepOne} />
            <Route path='/book-select-staff-overview' component={BookSelectStaffOverview} />
            <Route path='/book-select-staff' component={BookSelectStaff} />
            <Route path='/book-select-time' component={BookSelectTime} />
            <Route path='/book-select-service' component={BookSelectService} />
            <Route path='/book-cart' component={BookCart} />
            <Route path='/book-cart-cancel' component={BookCartCancel} />
            <Route path='/book-cart-confirm' component={BookCartConfirm} />
            <Route path='/book-success' component={BookSuccess} />
            <Route path='/book-add-services' component={BookAddService} />
            <Route path='/location' component={Location} />
            <Route path='/customer-login' exact component={CustomerLogin} />
            <Route path='/create-account' exact component={CreateAccount} />
            <Route path='/reset-password' exact component={ResetPassword} />
            <Route path='/verify' exact component={VerifyEmail} />
            <Route path='/customer-account' exact component={CustomerAccount} />
            <Route path='/customer-appointments' exact component={CustomerAppointments} />
            <Route path='/giftcard' exact component={GiftCardDetail} />
            <Route path='/cancellation-policy' component={CancellationPolicy} />

            <Route path='/404' component={View404} />
            <Route path='/' exact render={() => <Redirect to='/index' />} />
            <Route component={DefaultLayout} />
        </Switch>
    </Router>
)

export default App
